(function($) {


	
		var $window = $(window);
		var container; // = document.getElementById('canvas');

			var camera, scene, renderer;

			var raycaster = new THREE.Raycaster(); //, INTERSECTED;
			var mouse = new THREE.Vector2();

			//var raycaster_objects = [];




			var group, mountFint, moon, cube, plane;

			var targetRotation = 0;
			var targetRotationOnMouseDown = 0;

			var mouseX = 0;
			var mouseXOnMouseDown = 0;
			var hover = false;
			var mousedown = false;
			var speed;

			var canvas_width = document.body.clientWidth, //$window.width(), // 400,
    		canvas_height = window.innerHeight; //$window.height(); //400;

    		var windowHalfX = canvas_width / 2;
			var windowHalfY = canvas_height / 2;

			// check webgl:
  			var canvas = !! window.CanvasRenderingContext2D;
  			var webgl = ( function () { try { return !! window.WebGLRenderingContext && !! document.createElement( 'canvas' ).getContext( 'experimental-webgl' ); } catch( e ) { return false; } } )();

  			//console.log(webgl);
  			//var clock = new THREE.Clock;	

  			 var moonBumps, texturemountain;

			   


  			window.setupMountFint = function(){
				

	  			if ( document.getElementById('canvas')   ){

	  						$('canvas').remove();
	  						
			  			/*var progress = document.createElement('div');
							
							document.body.appendChild(progress);
							progress.className = "loadbar";*/

							var manager = new THREE.LoadingManager();
							manager.onProgress = function ( item, loaded, total ) {
							 /* progress.style.width = (loaded / total * 100) + '%';*/


							  if (loaded === total){
							  	//progress.className += " fade";

							  	//document.body.removeChild(progress);
								//
							  	window.init();
							  	
								  	//$('#canvas').removeClass('is-loading');
								  	//animate();

							  		var fadein =  setTimeout(function(){
							  			$('#canvas').addClass('slide-transition');
							  			//$('.arrow-down').addClass('show');
							  		}, 100);

							  		
							  }
							};


							 //var moonTexture =  new THREE.TextureLoader(manager).load('wp-content/themes/fint/dist/images/moon.jpg');
							 moonBumps =  new THREE.TextureLoader(manager).load('wp-content/themes/fint/dist/images/moon_bumps.jpg');
			      			//texturemountain =  new THREE.TextureLoader(manager).load('wp-content/themes/fint/dist/images/logoFint.png');
			      			/*var texturemountain2 =  new THREE.SVGLoader().load('wp-content/themes/fint/dist/images/logo.svg', function ( doc ) {

								scene.add( new THREE.SVGObject(doc) );
						
							});*/

						



	       		}
	       	};

	       	setupMountFint();




			window.init = function() {




				// zurücksetzen
				 targetRotation = 0;
				 targetRotationOnMouseDown = 0;

				 container = document.getElementById('canvas');


				camera = new THREE.PerspectiveCamera( 60, canvas_width / canvas_height, 1, 10000 );
				camera.position.y = 100;
				camera.position.z = 800;

				scene = new THREE.Scene();


				group = new THREE.Object3D();

			
					// instantiate a loader
					var loader = new THREE.SVGLoader();

					// load a SVG resource
					loader.load(
						// resource URL
						'wp-content/themes/fint/dist/images/logo3.svg',
						// called when the resource is loaded
						function ( data ) {

							//console.log("load that LOGO");

							var paths = data.paths;

							var mountFint = new THREE.Group();
							mountFint.scale.multiplyScalar( 0.55 );
							mountFint.scale.y *= -1;
							for ( var i = 0; i < paths.length; i ++ ) {
								var path = paths[ i ];
								var material = new THREE.MeshBasicMaterial( {
									color: path.color,
									side: THREE.DoubleSide,
									depthWrite: false
								} );
								var shapes = path.toShapes( true );
								for ( var j = 0; j < shapes.length; j ++ ) {
									var shape = shapes[ j ];
									var geometry = new THREE.ShapeBufferGeometry( shape );
									var mesh = new THREE.Mesh( geometry, material );
									mountFint.add( mesh );
								}
							}

							mountFint.position.y = 80;
							mountFint.position.x = -110;
							//mountFint.position.z = 5;
							

							scene.add( mountFint );
							 
						},
						// called when loading is in progresses
						function ( xhr ) {

							//console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );

						},
						// called when loading has errors
						function ( error ) {

							 console.log( 'An error happened' );

						}
					);


 


      			// MOON




      			if (webgl ){
      				geometry = new THREE.SphereGeometry( 50, 64, 64 );

      				//var moonTexture = THREE.ImageUtils.loadTexture( "wp-content/themes/fint/dist/images/moon.jpg" );
      				//var moonBumps = THREE.ImageUtils.loadTexture( "wp-content/themes/fint/dist/images/moon_bumps.jpg" );
					//var material = new THREE.MeshLambertMaterial( { color: 0x666666, ambient: 0xffffff, emissive: 0xeeeeee, overdraw: true, map: moonTexture } );
					//var material = new THREE.MeshPhongMaterial( { shininess: 30, color: 0xeeeeee,  /*ambient: 0xffffff,  specular: 0xffffff, */ emissive: 0x666666, /**/overdraw: true,  map: moonTexture,  bumpMap:moonBumps } );

					material = new THREE.MeshPhongMaterial( {
						color: 0xfefefe,
						specular: 0xffffff,
						//ambient: 0x222222,
						shininess: 0,
						//map: moonTexture,
						bumpMap: moonBumps,
						bumpScale: 5,
						displacementMap: moonBumps,
						displacementScale: 3,
						overdraw: true
					} );


					moon  = new THREE.Mesh( geometry, material );

				}else{
					geometry = new THREE.SphereGeometry( 60, 10, 10 );

					material = new THREE.MeshBasicMaterial( { color: 0xffffff, overdraw: true,wireframe:false } );
					var material2 = new THREE.MeshBasicMaterial( { color: 0x000000, overdraw: true,wireframe:true } );


					moon   = THREE.SceneUtils.createMultiMaterialObject(geometry, [material, material2]);


				}


//raycaster_objects.push(moon);

      			//moon.position.y = 50;
      			moon.position.x = -130;
				moon.position.z = -0;
				moon.rotation.y = -0.2;
				
      			group.add(moon);

      			group.rotation.y = -1.5;
      			//group.rotation.z = 100;
      			group.rotation.x = -0.3;
				group.position.y = 60;
				//group.position.x = -10;

				

      			//  group.scale.x = group.scale.y = group.scale.z = 0.55;
      			//  mountFint.scale.x = mountFint.scale.y = mountFint.scale.z = 0.5;
      			  
				group.scale.set( 0.9, 0.9, 0.9 );
      			scene.add(group);

/*

				  var newmoongroup = new THREE.Group();


					var moonShape = new THREE.Shape();
					var x = 0, y = 0, z = 10, s = 1;

					moonShape.moveTo( x - 148, y -26 );
					moonShape.lineTo( x - 66, y + 120); // erster spitz
					moonShape.lineTo( x - 42, y + 76);
					moonShape.lineTo( x - 1, y + 150);	// zweiter spitz
					moonShape.lineTo( x + 46, y + 62);
					moonShape.lineTo( x + 62, y + 90); // dritter spitz
					moonShape.lineTo( x + 129, y -26); // ecke unten rechts
					moonShape.lineTo( x - 148, y -26); // close
					
					var geometry = new THREE.ShapeGeometry( moonShape );
					var material = new THREE.MeshBasicMaterial( { color: 0x181818, overdraw: 0.5 } );
					var mesh = new THREE.Mesh( geometry, material ) ;
					mesh.position.set( x, y, z );
					//mesh.rotation.set( rx, ry, rz );
					mesh.scale.set( s, s, s );
					newmoongroup.add( mesh );


					// line
					var points = moonShape.getPoints();
					var geometry = new THREE.BufferGeometry().setFromPoints( points );
					var material = new THREE.LineBasicMaterial( { linewidth: 4, color: 0xfff3e2 } );
					var line = new THREE.Line( geometry, material );
					line.position.set( x, y, z );
					//line.rotation.set( rx, ry, rz );
					line.scale.set( s, s, s );
					newmoongroup.add( line );

					
					scene.add(newmoongroup);


*/


      			particle = new THREE.Object3D();
				   geometry = new THREE.TetrahedronGeometry(1, 0);
				   //geometry = new THREE.SphereGeometry( 2, 2 );

      			material = new THREE.MeshPhongMaterial({
				    color: 0xffffff,
				    //shading: THREE.FlatShading
				    flatShading: true
				  });
      			for (var i = 0; i < 200; i++) {
				    var mesh = new THREE.Mesh(geometry, material);
				    mesh.position.set(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5).normalize();
				    mesh.position.multiplyScalar(90 + (Math.random() * 700));
				    mesh.rotation.set(Math.random() * 2, Math.random() * 2, Math.random() * 2);
				    particle.add(mesh);
				  }
				  particle.position.z = -10;
				 scene.add(particle);


      // directional lighting
      var directionalLight = new THREE.DirectionalLight(0xffffff, 0.4 );
      directionalLight.position.set(-100, 100, 100 ).normalize();
      scene.add(directionalLight);

       // directional lighting
      var directionalLight_back = new THREE.DirectionalLight(0xffffff, 0.8 );
      directionalLight_back.position.set(100, -100, -200 ).normalize();
      scene.add(directionalLight_back);



     /* */
     var ambientLight = new THREE.AmbientLight( 0x888888 );
		scene.add( ambientLight );


	/*	var plane_geometry = new THREE.PlaneGeometry( canvas_width*100, canvas_height*100 );
		var plane_material = new THREE.MeshBasicMaterial( {color: 0x333333, opacity: 0} );
		var safarihack_plane = new THREE.Mesh( plane_geometry, plane_material );
		safarihack_plane.position.z = -2001;
		scene.add( safarihack_plane );
*/
		


		scene.fog = new THREE.Fog( 0x050505, 2000, 3500 );



				 if (webgl) {
				 	renderer = new THREE.WebGLRenderer({ alpha: true, antialias:true });

				 	speed = 0.005;
				 	//console.log('WebGLRenderer');

				 }else if (canvas) {
				 	renderer = new THREE.CanvasRenderer({ alpha: true });
				 	speed = 0.05;

				 }

				// renderer = new THREE.CanvasRenderer({ alpha: true });

				 renderer.setPixelRatio(window.devicePixelRatio ? window.devicePixelRatio : 1);

				 if (renderer) {
					renderer.setSize( canvas_width, canvas_height );
					container.appendChild( renderer.domElement );
				}


				

				 //var fadein =  setTimeout(function(){$('#canvas').addClass('show').removeClass('slide-out');}, 500);
				
			



/*
				document.addEventListener( 'mousedown', onDocumentMouseDown, false );
				document.addEventListener( 'touchstart', onDocumentTouchStart, false );
				document.addEventListener( 'touchmove', onDocumentTouchMove, false );
*/

/*
				function getRandomArbitrary(min, max) {
					  return Math.random() * (max - min) + min;
				}



				  document.addEventListener( 'mousedown', function(event){

				 		 
				        ////var intersects = raycaster.intersectObjects( raycaster_objects );
				        var intersects = raycaster.intersectObject( moon);

				       // console.log(intersects);

				        if (intersects.length > 0) {

				           // console.log(intersects);

				        
				 	 
									var tweenOut = new TWEEN.Tween( moon.scale ).to( {
									       x: 1,
									       y: 1,
									       z: 1 
									    }, 500 ).easing( TWEEN.Easing.Elastic.Out).start();


 								var tweenIn = new TWEEN.Tween( moon.scale )
										.to( {
									      // x: 1.2,  
									      // y: 1.2, 
									      // z: 1.2 
									        x: getRandomArbitrary(0.85, 1.2),
									       y: getRandomArbitrary(0.85, 1.2),
									       z:  getRandomArbitrary(0.85, 1.2) 
									    }, 100 )
									    .delay(0)
									    //.easing( TWEEN.Easing.Elastic.In)
									    .start()
										.onComplete(function () {
		                   			 		tweenOut.start();
		                				});

						}


					//	console.log('click');
        

				 } ); 

*/




		/*	function onDocumentMouseDown( event ) {

				//event.preventDefault();
				console.log('mousemove');
				//document.addEventListener( 'mousemove', onDocumentMouseMove, false );
				document.addEventListener( 'mouseup', onDocumentMouseUp, false );
				document.addEventListener( 'mouseout', onDocumentMouseOut, false );

				mouseXOnMouseDown = event.clientX - windowHalfX;
				targetRotationOnMouseDown = targetRotation;

			}

			function onDocumentMouseMove( event ) {

				 

				mouseX = event.clientX - windowHalfX;

				targetRotation = targetRotationOnMouseDown + ( mouseX - mouseXOnMouseDown ) * 0.02;

			}

			function onDocumentMouseUp( event ) {

				//document.removeEventListener( 'mousemove', onDocumentMouseMove, false );
				document.removeEventListener( 'mouseup', onDocumentMouseUp, false );
				document.removeEventListener( 'mouseout', onDocumentMouseOut, false );

			}

			function onDocumentMouseOut( event ) {

				//document.removeEventListener( 'mousemove', onDocumentMouseMove, false );
				document.removeEventListener( 'mouseup', onDocumentMouseUp, false );
				document.removeEventListener( 'mouseout', onDocumentMouseOut, false );

			}

			function onDocumentTouchStart( event ) {

				if ( event.touches.length === 1 ) {

					//event.preventDefault();

					mouseXOnMouseDown = event.touches[ 0 ].pageX - windowHalfX;
					targetRotationOnMouseDown = targetRotation;

				}

			}

			function onDocumentTouchMove( event ) {

				if ( event.touches.length === 1 ) {

					//event.preventDefault();

					mouseX = event.touches[ 0 ].pageX - windowHalfX;
					targetRotation = targetRotationOnMouseDown + ( mouseX - mouseXOnMouseDown ) * 0.05;

				}

			}*/
				

			   
			    function onPointerMove(event) {

					//var mouse = new THREE.Vector2();

					 var bounds = container.getBoundingClientRect();
  					mouse.x = ( (event.clientX - bounds.left) / container.clientWidth ) * 2 - 1;
  					mouse.y = - ( (event.clientY - bounds.top) / container.clientHeight ) * 2 + 1;

					
				}

			document.addEventListener("mousemove", onPointerMove, false );
			document.addEventListener("pointermove", onPointerMove, false );
			document.addEventListener("touchmove", onPointerMove, false );



			
			function onPointerDown(event) {

				mousedown = true;
				//console.log('mousedown');

			}

		document.addEventListener("mousedown", onPointerDown, false );
		document.addEventListener("pointerdown", onPointerDown, false );
		document.addEventListener("touchstart", onPointerDown, false );

		function onPointerUp( event ) {
			mousedown = false;
			//console.log('mouseup');
		
		}
		document.addEventListener("mouseup", onPointerUp, false);
		document.addEventListener("pointerup", onPointerUp, false);
		document.addEventListener("touchend", onPointerUp, false);



			//
			//var fps = 24;
			var animationframe;
			//cancelAnimationFrame( animationframe );
			//window.cancelAnimationFrame( );

			

			

			/*function animate() {

				requestAnimationFrame( animate );

				render();

			}*/
			function onWindowResize() {

					var width = document.body.clientWidth, //$(window).width(), //document.documentElement.clientWidth,
						height = window.innerHeight; //$(window).height(); //window.innerWidth

				  camera.aspect = width / height;
				  camera.updateProjectionMatrix();
				  renderer.setSize(width, height);
				}
			onWindowResize();
			window.addEventListener('resize', onWindowResize, false);


		 

			var moonspeed = 0,
			moonRotation = moon.rotation.y;

			function render() {

			 
			
		 


				raycaster.setFromCamera(mouse, camera);

				        var intersects = raycaster.intersectObject(moon);
				        if ( intersects.length > 0) {

				            //console.log(mousedown);
							//moonspeed = (moonspeed + 0.5)*0.01;
							if (mousedown) {moonspeed += (0.2 - moonspeed)*0.01;}

							$('html,body').css('cursor', 'pointer');
			 				//hover = true;

							//console.log(moonspeed);
				        }else{
							moonspeed += (0 - moonspeed)*0.01;

							$('html,body').css('cursor', 'default');
							//hover = false;
						}



					// TWEEN.update();


						targetRotation += speed;
						moonRotation += moonspeed;
						
						moon.rotation.y -= moonspeed;
						moon.rotation.z += moonspeed;

						var turn_speed = 0.008;
	
						group.rotation.y += ( targetRotation - group.rotation.y ) * turn_speed;


						 particle.rotation.z -= 0.0001;
		  					particle.rotation.y += 0.001;


						renderer.render( scene, camera );

				}



			function animate() {
			   // setTimeout(function() {

			   		if (!$('#canvas').length){
			   			  cancelAnimationFrame( animationframe );

			   		}else{
			       		animationframe =  requestAnimationFrame(animate);
			        	render();
			        	//console.log('yo');
			       }
			   // }, 1000 / fps);
			}

		animate();

		}; /// end INIT	
			


})(jQuery); // Fully reference jQuery after this point.