/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


            var $window = $(window),
                $document = $(document),
                $body = $('body');



          
          

            /*
            /// TEXT SHUFFLE  
            $.fn.textshuffle = function(e) {
              var i = {
                    str: "",
                    waitChar: "_",
                    charSpeed: 1,
                    moveFix: 4, //25,
                    moveRange: 0, //10,
                    moveTrigger: 4, //25,
                    fps: 60,
                    callback: null
                }, 
                n = $.extend(i, e);
              return this.each(function(e) {
                  var i, s, o, r, a, l, h, u, c, d = function() {
                      h = l,
                      u = !0;
                      for (var t = a; t <= r; t++) {
                          if (0 != o[t] && null != o[t]) {
                              u = !1;
                              var e = o[t];
                              if (Math.abs(e) <= n.moveTrigger) {
                                  var d = Math.min(Math.max(s.charCodeAt(t) + e, 33), 126);
                                  h += String.fromCharCode(d)
                              } else
                                  h += n.waitChar;
                              e > 0 ? o[t] -= 1 : o[t] += 1
                          } else
                              a == t - 1 && (a = t,
                              l = s.substring(0, a)),
                              h += s.charAt(t);
                          i.get(0).textContent = h
                      }
                      r <= s.length ? r += n.charSpeed : c = !0,
                      u && c && (clearInterval(i.data("id")),
                      i.html(l),
                      i.data("run", !1),
                      n.callback && n.callback())
                  };
                  if (i = $(this),
                  i.data("run") || i.data("html", i.html()),
                  i.data("run", !0),
                  i.html(n.str),
                  n.str = i.text(),
                  i.html(n.waitChar),
                  n.str) {
                      s = n.str,
                      o = [];
                      for (var p = 0; p <= n.str.length - 1; p++) {
                          var f = s.charAt(e);
                          o[p] = " " != f ? (n.moveFix + Math.round(Math.random() * n.moveRange)) * (Math.round(Math.random()) - .5) * 2 : 0
                      }
                      r = 0,
                      a = 0,
                      l = "",
                      clearInterval(i.data("id"));
                      var g = setInterval(d, 1e3 / n.fps);
                      i.data("id", g)
                  }
              })
          }



              $('.claim').removeClass('slide-in').textshuffle({
                str: $('.claim').html(),
                callback: function() {
                   // s.html(r),
                   // e.start()
                }
              });
              */
         /* $('.brand').hover(function(){
            $(this).textshuffle({
              str: $(this).data('txt'),
            });
          });   */ 



          function videocontrol(){

                var $video = $('video');
                var $volume_btn = $('.btn-volume');

                var muteVolume = function() {
                      if($video.prop('muted')===true) {
                        $video.prop('muted', false);
                        //console.log('unmute');
                        //$ghinda_volume.slider('value', video_volume);
                       //$video.get(0).play();

                        $volume_btn.addClass('sound-on');
                      } else {
                        $video.prop('muted', true);
                        //$ghinda_volume.slider('value', '0');

                        $volume_btn.removeClass('sound-on');
                      }
                    };

                    $volume_btn.click(muteVolume);



          }
          
          if ($('.btn-volume').length){
            videocontrol();  
          }


          // activate swiper in tab:
         $('.collapse').on('shown.bs.collapse', function (e) {
              //e.target // newly activated tab
              //e.relatedTarget // previous active tab
             /* var mySwiper = $('.tab-pane .swiper-container')[0].swiper;
              // Now you can use all slider methods like
              mySwiper.update();*/
                //console.log(e.target);
                initswiper(e);

                 /*var swip = $('.tab-pane.active').find('.swiper-container')[0].swiper;
                swip.update(); */

            });




          

          
          const observer = lozad('.lozad', {
              loaded: function(el) {
                  // Custom implementation on a loaded element
                  el.parentElement.classList.add('loaded');
              },
          });
            observer.observe();



          $.fn.isInViewport = function() {
            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight();
      
            var viewportTop = $(window).scrollTop();
            var viewportBottom = viewportTop + window.innerHeight;
      
           // return elementBottom > viewportTop && elementTop < viewportBottom - 100;
           return elementBottom > viewportTop && elementTop < ( viewportBottom + 50);
           //return  elementTop < ( viewportBottom + 100);

          };



            function slidein() {
              

             var time = 0;

             //console.log(' trigger slidein'+ time);


              $(".slide-transition:not(.inview)").each(function() {
                var $this = $(this);
                if ($this.isInViewport() ) {
                  $this.addClass("inview");
                 
                  // hack because of delay bug on home:
                  if($('.home').length){  
                    $this.removeClass('slide-in','slide-out');
                    //console.log('home slidein '+ $this +' '+ time);


                  }else{
                    var timeout;
                    clearTimeout(timeout);
                    timeout = setTimeout(function() {
                      $this.removeClass('slide-in','slide-out');
                       //console.log('slidein '+ $this +' '+ time);
                    }, time += 50);

                  }

                
                 
                }   
              });
            }
            slidein();

       /*   function slidein_() {

           const images = document.querySelectorAll('.slide-transition:not(.inview)');

           var IntersectionObserverCallback = function(entries) { 
            Array.prototype.forEach.call(entries, function(entry) {
              if (entry.intersectionRatio > 0) {
                var time = 0;
                var timeout = setTimeout(function() {
                  entry.target.classList.add('inview');
                  entry.target.classList.remove('slide-in','slide-out');
                  slidein_observer.unobserve(entry.target);
                  console.log(time);
                }, time+= 50);
                //time += 50;

              } else {
                entry.target.classList.remove('inview');
              }
            });
          };

          var slidein_observer = new IntersectionObserver(IntersectionObserverCallback);

          
              Array.prototype.forEach.call(images, function(target) {
                slidein_observer.observe(target);
              });

            }*/

            
          /*  if ($('.info[data-body_class*="home"]').length ) {

              // HOME INTRO goes here
                setTimeout(function() {
                  slidein();
 
                 }, 1000);
            }else{
                slidein();
               // console.log('not home ');
            }
*/


            function debounce(func, wait, immediate) {
                var timeout;
                return function() {
                  var context = this, args = arguments;
                  var later = function() {
                    timeout = null;
                    if (!immediate) {func.apply(context, args);}
                  };
                  var callNow = immediate && !timeout;
                  clearTimeout(timeout);
                  timeout = setTimeout(later, wait);
                  if (callNow) {func.apply(context, args);}
                };
              }



              var myEfficientFn = debounce(function() {
                if ($window.scrollTop() > 100){
                  $('.arrow-down, .menu-link, .arrow-down').removeClass('show');

                    } else{
                          $('.arrow-down, .menu-link, .arrow-down').addClass('show');                  
                    }

                    // if browser with no observer : 
                    slidein();              
              }, 10);

              var myScrollFn =  function() {
                if ($window.scrollTop() > 100){
                  $('.arrow-down, .menu-link, .arrow-down').removeClass('show');

                    } else{
                          $('.arrow-down, .menu-link, .arrow-down').addClass('show');                  
                    }

                    // if browser with no observer : 
                    slidein();              
              };

              
              window.addEventListener('scroll', myScrollFn);


        

          /*  $window.on('scroll',  function() {
              if ($window.scrollTop() > 100){
                    $('.arrow-down, .menu-link, .arrow-down').removeClass('show');

              } else{
                    $('.arrow-down, .menu-link, .arrow-down').addClass('show');                  
              }

              // if browser with no observer : 
              slidein();

 
          });*/




     var $pjaxContainer = $("#main");
            //$pjaxContainer.addClass("fade in");

           // Get the pjax container css transition speed from its pseudocontent
          var transitionSpeed = '300'; //parseInt(site.helper.getPseudoContent($pjaxContainer[0], ":after", true), 10);

              

          //$(container).addClass('show');


          function changebody(){
               
               //$pjaxContainer.find('.slide-transition').removeClass('slide-in');
               
               // console.info( $(container +' .slide-transition').length);
                $body.attr('class', $('.info').data('body_class') );   



               var bgcolor = "#ffffff",
               rosa = '#E91E63',
                     blau = '#2196F3';

               /* if ($pjaxContainer.find('.page-work').length){
                     bgcolor = '#222';
                     

                 } */
               
               if ($pjaxContainer.find('.single-work').length){
                      //console.log('found single-work');

                      bgcolor = $('.single-work').data('color');

                     $('body').css({'background': bgcolor});
                    //$('body').css({'background': 'linear-gradient(180deg,'+bgcolor+' 0,rgba(255,255,255,0) 100vh)'});


                       
               /* }else if ($pjaxContainer.find('.filter').length){

                      $('.wrap, .content-info_').css({'background': 'linear-gradient(#888,#eee 50vh,#fff 100vh)'});

*/                      // console.log(bgcolor);


                       /*   if (bgcolor === "#ffffff"){

                              $('a.menu-link').css({'color': '#222'});

                          }else{
                               $('a.menu-link').css({'color': '#ffffff'});

                          }*/

  
                  }else{

                    //$('.wrap, .content-info_').css({'background': bgcolor}); 
                    //$('.wrap, .content-info_').css({'background': 'linear-gradient(#5a42ce,#fd89b0 50vh,#fff 100vh)'});
                    //$('.wrap, .content-info_').css({'background': 'linear-gradient(#5a42ce,#fd89b0 50vh,#fff 100vh)'});


                    //$('.wrap, .content-info_').css({'background': 'linear-gradient(#236ab1,#d6a9c6 50vh,#fff 100vh)'});
                    //$('body').css({'background': 'linear-gradient(rgb(0, 12, 70), rgb(212, 114, 164) 36vh, rgb(255, 255, 255) 90vh)'});
                    $('body, a.menu-link').removeAttr( 'style' );
                    
                    //$('.wrap, .content-info_').css({'background': 'linear-gradient(rgba(35, 106, 177,0.5), rgba(214, 169, 198,0.5) 50vh, rgba(255, 255, 255,0) 100vh)'});
                    
                     //$('a.menu-link').css({'color': '#ffffff'});

                   // #243786  #613498  #98ffde


                    //#236ab1   #d6a9c6
                    //linear-gradient(#9f27ea, #5df5bd 50vh, #fff 100vh)

                  }


                   
                    //rgb(237, 113, 113) 0,transparent 80vh)
                      //initswiper(); 

            }

          


           changebody();


          // Change post EDIT BUTTON in ADMIN BAR
               function admin_functions(){
                  var pid = $('.info').data('id');
                  //console.info(pid);
                  if(pid){
                    $('#wp-admin-bar-edit .ab-item').attr('href','/wp-admin/post.php?post='+pid+'&action=edit');
                  }

               }


                function do_filter(){


                    $('#filterpanel').removeClass('hidden');
                    //$('.filterlist').children().first().addClass('active').siblings().removeClass('active');

                    $('.filter-btn[data-filter="hentry"]').addClass('active').siblings().removeClass('active');

/* collaps
        $('.filter').click(function(e){
          e.preventDefault();
          $('#filterpanel, .filter').toggleClass('active');
           $('#filterpanel').stop(true, true).slideToggle('666', "easeInOutExpo"); //.toggleClass('active');
         
        });
        var myTimer=false;
        $('#filterpanel').hover(function(){
            //mouse enter
            clearTimeout(myTimer);
        });
        $('#filterpanel').mouseleave(function(){
        myTimer =  setTimeout(function() {
            //$('.cat-filter, .photographers .entry-content').stop(true, true).removeClass('active').slideUp('666', "easeInOutExpo"); //.animate({ height: 0}, 666 , "easeInOutExpo");
            $('#filterpanel, .filter').removeClass('active');
            $('#filterpanel').stop(true, true).slideUp('666', "easeInOutExpo");

          }, 3000);
        });
*/


        /*activate filter */
       /* $('.filter-btn').each(function() {
          var selector = $(this).attr('data-filter');
         if ($('.content .projekte').hasClass(selector)){
            $(this).addClass( "visible active" );
          }
        });*/
  


            function containsAll(needles, haystack){
                          for(var i = 0 , len = needles.length; i < len; i++){
                             if($.inArray(needles[i], haystack) === -1) {return false;}
                          }
                          return true;
                        }

                function hide() {
                    var target = $('.work-item-block');
                    var checked_values = $('.filter-btn.active').map(function () { return $(this).attr('data-filter'); }).get(); // ["cat1", "cat2", "cat3"]

                   if (!checked_values.length) {
                                       
                        target.addClass('show').removeClass('d-none'); //.stop().fadeIn();
                        /*if($container){
                            $container.packery('layout');
                          }*/
            
              
        }else{



                    $(target).each(function(){

                      // get all the classes from the object:
                      var catsArray = $(this).attr('class').split(' ');
                      
                      // variante : alle filter müssen zutreffen:
                      if ( containsAll( checked_values, catsArray ) ) {
                          $(this).addClass('show ').removeClass('slide-transition_ d-none'); //.stop().fadeIn();

                      }else{
                         $(this).removeClass('show').addClass('d-none'); //.stop().fadeOut();
                      }
                       
                       /*if($container){
                          $container.packery();//.packery('layout'); 
                       }*/

                     /* // variante : min 1 filter muss zutreffen:
                      var hits = false;
                      $.each(catsArray, function(i, val){ // check if any values of this marker are in checked_values
                          if($.inArray(val, checked_values) !== -1){
                            hits = true;
                          }
                         });

                      if (!hits ) {
                          //console.info('ist UNsichtbar: ' +$(this).attr('class'));
                          $(this).stop().fadeOut();
                      }else{
                          //console.info('ist sichtbar: ' +$(this).attr('class'));
                          $(this).stop().fadeIn();
                      }
                      // end variante 2 */

                    });

           


        }
      }// end hide



             $(".filter-btn").click(function(){
                //if(!$(this).hasClass('active')){
                     $(this).toggleClass('active').siblings().removeClass('active');
                      hide();
                      slidein();
                //}
              

             });

            

    }    
    if($('.filter').length){
        do_filter();
    }   
   

  // end filter


    // test mit stehenbleiben:
/*    $(document).on('click','.work-item-block, .work-item',function(){
       $(this).removeClass('slide-transition');
    });
*/
 
 


          



               // pushHistoryState is true by default. Pass false for it if you are loading
          // after window.onpopstate or the forward button will break
          function loadPage(href, pushHistoryState) {
                  if(typeof pushHistoryState === "undefined") {
                    pushHistoryState = true;
                  }


                  $pjaxContainer.find('.slide-transition').addClass('slide-out');

                  // After the hide transition ends, load the new content
                  setTimeout(function() {
                   /* if(site.swiper) {
                      site.swiper.destroy();
                    }
                    if(site.videos) {
                      site.videos.destroy();
                    }*/
                   // $pjaxContainer.empty();
                    $.pjax({
                      url: href,
                      container: '#main',
                      push: pushHistoryState,
                      timeout: 4000,
                       fragment: '#main'
                    });
                  }, transitionSpeed);
                }

       
         // When new content is loaded
                $pjaxContainer.on("pjax:end", function() {

                  //$('#loading').hide();
                 // $('body').removeClass('modal-open');
                      /// ADMIN MODUS:
                   if ( $('#wp-admin-bar-edit').length){
                      admin_functions();
                   }
                    if ( $('.info[data-body_class*="home"]').length){
                        //do_masonry();
                        //window.init();
                        window.setupMountFint();

                                          // $('.arrow-down').addClass('show');

                    }
                      //do_lazyload();
                      observer.observe();
                      
                      //fint_imagesLoaded();

                      changebody();
                      slidein();

                     /* if($('.work-item, .work-item-block').length){
                           init_tilt();
                      }*/

                            if($('.filter').length){
                               do_filter();
                            }  
                       if ($('.btn-volume').length){
                             videocontrol();  
                          }

              });


                 // Load links from site nav using pjax
            $document.on("click", "a", function(event) {

                    var $this = $(this);
                    //console.log($this.attr("href") +' '+ $this.attr("href").indexOf("#"));
                    // Ignore links to new windows or any links that don't match the window host
                    if( 
                      $this.attr("target") === "_blank" ||
                      $this[0].host !== window.location.host ||
                      $this.attr("href").indexOf("wp-admin") > 0 ||
                       $this.attr("href").indexOf(".pdf") > 0 ||
                      $this.attr("href").indexOf("#") === 0 ||
                      $this.hasClass('toggle-link') ||
                        $this.hasClass('no-ajax') || $this.parent().hasClass('no-ajax') || 
                      $this.hasClass('qtranxs_text') 

                      
                    ) {
                      console.info('no Ajax');
                      return;
                    }

                    // Ignore links to info panels if they will be opening next to the nav
                   /* if(
                      $this.parent().hasClass("menu-item-object-info-panel") &&
                      site.nav &&
                      ! site.nav.isFullWidthNav()
                    ) {
                      return;
                    }*/
 

                    // if is main menu item change active class to current item:
                    if ($this.parent().hasClass("menu-item") ){
                      $(".modalnav .active").removeClass('active');
                      $this.parent().addClass('active');
                      //console.info('kuckuck' );
                    }



                    event.preventDefault();

                    // Load the page!
                    loadPage($this.attr("href"));
            });

            // On popstate (e.g. back/forwards), load the page, but pass false to prevent
            // pushing another history state which breaks the forward button
            $pjaxContainer.on("pjax:popstate", function(event) {
               event.preventDefault();
              loadPage(window.location.href, false);
            });



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

              $(document).on('click', 'a[href$=".pdf"]', function() {
                      $(this).attr('target', "_blank");
                    });
                    // Open all urls that don't belong to our domain in a new window or tab
                    $(document).on('click', "a[href^='http']:not([href*='" + window.location.host + "'])", function() {
                      $(this).attr("target", "_blank");
                    });

    



/*

          //// CANVAS TEST
            var   canvas = document.querySelector('canvas'),
         ctx = canvas.getContext('2d'),
   particles = [],
patriclesNum = 500,
           w = $('.menu-link').width(),
           h = $('.menu-link').height(),
      colors = ['#ff0000','#00ff00','#0000ff']; //,'#6ddaf1','#f1e85b'];
 
//canvas.width = 100%;
//canvas.height = 100%;
//canvas.style.left = (window.innerWidth - 500)/2+'px';

//if(window.innerHeight>500)
//canvas.style.top = (window.innerHeight - 500)/2+'px';
  

 // canvas.style.width ='100%';
 // canvas.style.height='100%';
  // ...then set the internal size to match
 //canvas.width  = canvas.clientWidth;
  //canvas.height = canvas.clientHeight;
   canvas.width  = $('.menu-link').width();
  canvas.height = $('.menu-link').height();

function Factory(){  
  this.x =  Math.round( Math.random() * w);
  this.y =  Math.round( Math.random() * h);
  this.rad = Math.round( Math.random() * 1) + 1;
  this.rgba = colors[ Math.round( Math.random() * 3) ];
  this.vx = Math.round( Math.random() * 3) - 1.5;
  this.vy = Math.round( Math.random() * 3) - 1.5;
}



function findDistance(p1,p2){  
  return Math.sqrt( Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2) );
}

function draw(){
  ctx.clearRect(0, 0, w, h);
  ctx.globalCompositeOperation = 'lighter';
  for(var i = 0;i < patriclesNum; i++){
    var temp = particles[i];
    var factor = 1;
     
    for(var j = 0; j<patriclesNum; j++){
      
       var temp2 = particles[j];
       ctx.linewidth = 0.5;
      
       if(temp.rgba === temp2.rgba && findDistance(temp, temp2)<50){
          ctx.strokeStyle = temp.rgba;
          ctx.beginPath();
          ctx.moveTo(temp.x, temp.y);
          ctx.lineTo(temp2.x, temp2.y);
          ctx.stroke();
          factor++;
       }
    }
    
    
    ctx.fillStyle = temp.rgba;
    ctx.strokeStyle = temp.rgba;
    
    ctx.beginPath();
    ctx.arc(temp.x, temp.y, temp.rad*factor, 0, Math.PI*2, true);
    ctx.fill();
    ctx.closePath();
    
  // ctx.beginPath();
   // ctx.arc(temp.x, temp.y, (temp.rad+5)*factor, 0, Math.PI*2, true);
   // ctx.stroke();
   // ctx.closePath();
    

    temp.x += temp.vx;
    temp.y += temp.vy;
    
    if(temp.x > w){temp.x = 0;}
    if(temp.x < 0){temp.x = w;}
    if(temp.y > h){temp.y = 0;}
    if(temp.y < 0){temp.y = h;}
  }
}


window.requestAnimFrame = (function(){
  return  window.requestAnimationFrame       ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame    ||
          function( callback ){
            window.setTimeout(callback, 1000 / 60);
          };
})();

(function init(){
  for(var i = 0; i < patriclesNum; i++){
    particles.push(new Factory());
  }
})();

(function loop(){
  draw();
  requestAnimFrame(loop);
})();*/


          /// END CANVAS TEST








      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page



          //$('.brand svg').addClass('pop-in');








      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
